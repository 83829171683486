import {
  deleteStoredItem,
  getStoredItem,
  setStoredItem,
} from "shared/services/storage.service";
import { sendMessageToNative } from "shared/utils/app.utils";
import { v4 as uuidv4 } from "uuid";

export const setUserAnonymousUUIDHandler = (state, action) => {
  const uuid = getStoredItem("uuid") ?? uuidv4();
  setStoredItem("uuid", uuid);
  state.me.anonymousUUID = uuid;
};

//not being user and probably wont be, because we're conserving the user uuid unless he explicity deletes cache
export const deleteUserAnonymousUUIDHandler = (state, action) => {
  deleteStoredItem("uuid");
  state.me.anonymousUUID = null;
};

export const deleteUserFavoriteSuccessHandler = (state, action) => {
  let list = state.favorite.data.data;
  const businessId = action.meta.arg.payload.business;

  const index = list?.findIndex((it) => it.id === businessId);
  if (index > -1) {
    list?.splice(index, 1);
  }
};

export const authSuccessHandler = (state, action) => {
  state.me.data = action.payload?.data;
  sendMessageToNative({ event: "syncCookies" });
};

export const disconnectSuccessHandler = (state, action) => {
  state.me.data = null;
  sendMessageToNative({ event: "syncCookies" });
};

export const setBusinessUserRoleHandler = (state, action) => {
  state.me.data.relatedBusiness.role = action.payload;
};

export const setBusinessUserNoneHandler = (state, action) => {
  state.me.data.relatedBusiness = null;
};

export const setBusinessUserHandler = (state, action) => {
  state.me.data.relatedBusiness = action.payload;
};
