import api from "shared/utils/api.utils";
import { deleteAccessToken, setAccessToken } from "./storage.service";

const routes = {
  signin: "/user-module/auth/signIn",
  signup: "/user-module/auth/signUp",
  disconnect: "/user-module/auth/disconnect",
  me: "/user-module/user/me",
  verifyEmail: "/user-module/user/verifyEmail",
  favorite: {
    cru: "/user-module/favorite",
    delete: (businessId) => "/user-module/favorite/" + businessId,
  },
  users: "/user-module/user",
};

export const registerUserService = async (payload) => {
  const response = await api.post(routes.signup, payload?.body, {
    params: payload?.query,
  });

  return response;
};

export const loginUserService = async (payload) => {
  const response = await api.post(routes.signin, payload?.body, {
    params: payload?.query,
  });

  return response;
};

export const getMeService = async (payload) => {
  return await api.get(routes.me, { params: payload?.query });
};

export const verifyUserEmailService = async (params) => {
  return await api.get(routes.verifyEmail, { params });
};

export const logoutUserService = async () => {
  await api.post(routes.disconnect);
  return deleteAccessToken();
};

export const updateMeService = async (payload) => {
  return await api.patch(routes.me, payload);
};

export const setFavoriteService = async (payload) => {
  return await api.post(routes.favorite.cru, payload);
};

export const getFavoriteService = async () => {
  return await api.get(routes.favorite.cru);
};

export const deleteFavoriteService = async (payload) => {
  return await api.delete(routes.favorite.delete(payload.business));
};
