import axios from "axios";
import { api_url } from "shared/config/api.config";
import { getStoredItem } from "shared/services/storage.service";

const api = axios.create({
  baseURL: api_url,
  withCredentials: true,
});

api.interceptors.request.use((config) => {
  const anonymousUUID = getStoredItem("uuid");

  if (anonymousUUID) {
    config.headers["anonymousuuid"] = anonymousUUID;
  }

  return config;
});

export default api;
