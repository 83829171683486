import {
  LayoutContentContainer,
  TitledContent,
} from "shared/components/shared/core/Container";
import { Orders } from "shared/components/order/Orders";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getOrdersAsync, setOrderInit } from "shared/redux/order.slice";
import { paginationLimit } from "shared/constants/shared.constant";
import PullToRefresh from "react-simple-pull-to-refresh";
import LoaderPortal from "shared/components/shared/utils/LoaderPortal";
import _ from "lodash";
import BaseSkeleton from "shared/components/shared/base/BaseSkeleton";

export default () => {
  const [page, setPage] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    load();
    return () => dispatch(setOrderInit("list"));
  }, []);

  const load = (params) => {
    const reset = params?.reset === true;

    const fetchPage = reset ? 0 : page;

    dispatch(
      getOrdersAsync({
        payload: {
          query: {
            offset: fetchPage * paginationLimit,
            limit: paginationLimit,
            variant: "public",
          },
        },
        options: {
          onSuccess: () => {
            setPage(fetchPage + 1);
          },
          reducerOptions: {
            reset,
          },
        },
      })
    );
  };

  return (
    <LoaderPortal
      loader={
        <LayoutContentContainer horizontalPadding={true} headerPadding={true}>
          {_.range(3).map((__, index) => (
            <BaseSkeleton
              className="w-full h-[180px] rounded-2xl mb-2"
              key={index}
            />
          ))}
        </LayoutContentContainer>
      }
      reducerPath="order.list"
      isList
    >
      <LayoutContentContainer>
        <PullToRefresh
          onRefresh={async () => await Promise.resolve(load({ reset: true }))}
        >
          <TitledContent title="Vos commandes">
            <Orders onLoadMoreClick={load} />
          </TitledContent>
        </PullToRefresh>
      </LayoutContentContainer>
    </LoaderPortal>
  );
};
