import { logoutUserAsync, setUserAnonyousUUID } from "shared/redux/user.slice";
import { useDispatch } from "react-redux";
import UserSetting from "./UserSetting";
import { useNavigate } from "react-router-dom";
import SettingListItem from "shared/components/shared/core/SettingListItem";

export default () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const onDisconnect = () => {
    dispatch(
      logoutUserAsync({
        options: {
          onSuccess: () => {
            dispatch(setUserAnonyousUUID());
          },
        },
      })
    );
  };

  return (
    <SettingListItem
      data={{
        label: "Déconncter",
        onClick: onDisconnect,
        showRightIcon: false,
        leftIcon: "hugeicons:logout-05"
      }}
    />
  );
};
