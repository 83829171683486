import "imports.css";
import "global.css";
import "scrollbar.css";
import "shared/config/moment.config";
import { Provider } from "react-redux";
import { skeletonTheme } from "shared/constants/theme.constant";
import Main from "Main";
import store from "./shared/redux/store";
import { QueryClient, QueryClientProvider } from "react-query";
import { SkeletonTheme } from "react-loading-skeleton";
import ToastContainer from "shared/components/shared/core/ToastContainer";
import ThemeSwitcherPortal from "shared/components/shared/utils/ThemeSwitcherPortal";

const queryClient = new QueryClient();

export default () => {
  return (
    <ThemeSwitcherPortal>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ToastContainer />
          <SkeletonTheme {...skeletonTheme}>
            <Main />
          </SkeletonTheme>
        </QueryClientProvider>
      </Provider>
    </ThemeSwitcherPortal>
  );
};
