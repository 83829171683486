import { LayoutContentContainer } from "shared/components/shared/core/Container";
import UserProfileAuthPortal from "./UserProfileAuthPortal";
import { WithResponsiveRightContentContainer } from "business/components/shared/SideContainer";
import { tabsBreadcrumbs } from "business/constants/tabsBusiness.constant";

const UserProfile = () => {
  return (
    <LayoutContentContainer horizontalPadding={false} headerPadding={false}>
      <UserProfileAuthPortal />
    </LayoutContentContainer>
  );
};

export default WithResponsiveRightContentContainer(UserProfile, {
  breadCrumbItems: [tabsBreadcrumbs.me],
});
