import BaseButton from "shared/components/shared/base/BaseButton";
import BaseInput from "shared/components/shared/base/BaseInput";
import { loginUserAsync } from "shared/redux/user.slice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PasswordInput from "../shared/core/PasswordInput";
import { toast } from "react-toastify";
import Toast from "../shared/core/Toast";

export default ({ onChangeToRegisterClick, variant }) => {
  const { loading } = useSelector((state) => state.user.auth);

  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const onEnterHandler = (e) => {
      if (e.key === "Enter") {
        onSubmit();
      }
    };

    document.addEventListener("keypress", onEnterHandler);

    return () => document.removeEventListener("keypress", onEnterHandler);
  }, [form]);

  const onChange = (name) => (e) => {
    setForm((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };

  const onSubmit = () => {
    dispatch(
      loginUserAsync({
        payload: {
          body: form,
          query: { variant: variant },
        },
        options: {
          onError: (error) => {
            toast(
              <Toast
                type="error"
                title="Erreur Login"
                message={error.message}
              />
            );
          },
        },
      })
    );
  };

  return (
    <div className="w-full h-full flex flex-col items-center justify-between">
      <div className="w-full text-2xl text-center">Se connecter</div>
      <div className="w-full">
        <BaseInput
          placeholder="Email"
          className="lg:py-4 mb-4"
          value={form.email}
          onChange={onChange("email")}
          leftIcon="hugeicons:mail-02"
          tooltip="Email"
        />
        <PasswordInput
          placeholder="Mot de passe"
          className="lg:py-4 mb-12"
          value={form.password}
          onChange={onChange("password")}
          leftIcon="hugeicons:lock-key"
          tooltip="Mot de passe"
        />
        <BaseButton.Contained
          primary
          className="w-full rounded-full mb-4"
          onClick={onSubmit}
          loading={loading}
        >
          Se connecter
        </BaseButton.Contained>

        <div
          className="w-full text-center text-lg cursor-pointer"
          onClick={onChangeToRegisterClick}
        >
          Créer un compte
        </div>
      </div>
    </div>
  );
};
