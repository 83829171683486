import {
  deleteFavoriteService,
  getFavoriteService,
  getMeService,
  loginUserService,
  logoutUserService,
  registerUserService,
  setFavoriteService,
} from "shared/services/user.service";
import reduxUtils from "./utils/redux.utils";
import {
  createNodeThunkMiddleware,
  createThunkMiddleware,
} from "./utils/thunk.utils";
import { createSlice } from "@reduxjs/toolkit";
import {
  authSuccessHandler,
  deleteUserFavoriteSuccessHandler,
  disconnectSuccessHandler,
  setBusinessUserHandler,
  setBusinessUserNoneHandler,
  setBusinessUserRoleHandler,
  setUserAnonymousUUIDHandler,
} from "./reducerHandlers/userReducerHandler";

const managementActions = reduxUtils.createManagementActions("/user");

const nodeThunkMiddleware = createNodeThunkMiddleware(managementActions);

export const getMeAsync = nodeThunkMiddleware(
  "/user/fetch/me",
  getMeService,
  "me"
);

export const registerUserAsync = nodeThunkMiddleware(
  "/user/register",
  registerUserService,
  "auth"
);

export const loginUserAsync = nodeThunkMiddleware(
  "/user/login",
  loginUserService,
  "auth"
);

export const logoutUserAsync = nodeThunkMiddleware(
  "/user/logout",
  logoutUserService,
  "me"
);

export const getFavoritesAsync = nodeThunkMiddleware(
  "/user/favorite/get",
  getFavoriteService,
  "favorite"
);

export const setFavoriteAsync = createThunkMiddleware(
  "/user/favorite/set",
  setFavoriteService
);

export const deleteFavoriteAsync = createThunkMiddleware(
  "/user/favorite/delete",
  deleteFavoriteService
);

const initialState = {
  me: {
    loading: true,
    data: undefined,
    anonymousUUID: undefined,
    hasCheckedAuthentification: false,
  },
  auth: {
    loading: false,
  },
  favorite: {
    loading: false,
    error: undefined,
    data: {
      count: 0,
      data: [],
    },
  },
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setBusinessUserRole: setBusinessUserRoleHandler,
    setBusinessUserNone: setBusinessUserNoneHandler,
    setBusinessUser: setBusinessUserHandler,
    setUserAnonyousUUID: setUserAnonymousUUIDHandler,
  },
  extraReducers: (builder) => {
    reduxUtils.tookit
      .buildManagementCases(builder, managementActions, initialState)
      .addCase(
        getMeAsync.fulfilled,
        reduxUtils.hof.onRootState.setter.setWithPathFromThunk("me.data")
      )
      .addCase(loginUserAsync.fulfilled, authSuccessHandler)
      .addCase(registerUserAsync.fulfilled, authSuccessHandler)
      .addCase(logoutUserAsync.fulfilled, disconnectSuccessHandler)
      .addCase(
        getFavoritesAsync.fulfilled,
        reduxUtils.hof.onRootState.setter.setWithPathFromThunk("favorite.data")
      )
      .addCase(deleteFavoriteAsync.fulfilled, deleteUserFavoriteSuccessHandler);
  },
});

export const mergeUserNodeData = managementActions.mergeNodeData;
export const setUserInit = managementActions.setInit;

export const {
  setUserAnonyousUUID,
  setBusinessUserRole,
  setBusinessUserNone,
  setBusinessUser,
} = slice.actions;

export const userReducer = slice.reducer;
