import { ExploreBusinessList } from "public/components/explore/ExploreBusiness";
import BaseButton from "shared/components/shared/base/BaseButton";
import {
  LayoutContentContainer,
  TitledContent,
} from "shared/components/shared/core/Container";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getFavoritesAsync, setUserInit } from "shared/redux/user.slice";

const UserFavoriteTab = () => {
  const {
    data: { data: businessList },
  } = useSelector((state) => state.user.favorite);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFavoritesAsync());
    return () => dispatch(setUserInit("favorite"));
  }, []);

  return (
    <LayoutContentContainer>
      <TitledContent title="Your favorites">
        <ExploreBusinessList data={businessList} />
      </TitledContent>
    </LayoutContentContainer>
  );
};

const FavoriteAnonymousTab = () => {
  const navigate = useNavigate();

  return (
    <LayoutContentContainer>
      <div className="w-full h-full pt-[100px]">
        <TitledContent
          title="Créer votre compte Swiftr"
          titleClassName="w-full text-center mb-8"
        >
          <BaseButton.Contained primary
            className="w-full flex flex-row items-center justify-center rounded-full py-2 ml-1"
            onClick={() => navigate("/me/auth")}
          >
            S'authentifier
          </BaseButton.Contained>
        </TitledContent>
      </div>
    </LayoutContentContainer>
  );
};

export default () => {
  const { data: user } = useSelector((state) => state.user.me);

  if (user) {
    return <UserFavoriteTab />;
  }

  return <FavoriteAnonymousTab />;
};
