import classnames from "classnames";
import { useDispatch } from "react-redux";
import _ from "lodash";
import {
  createOrderAsync,
  mergeOrderNodeData,
  setOrderInit,
  setOrderNodeFieldData,
} from "shared/redux/order.slice";
import { useNavigate } from "react-router-dom";
import useBaseSelector from "shared/hooks/useBaseSelector";
import BusinessCart from "shared/components/order/cart/BusinessCart";
import { useEffect } from "react";
import { LayoutContentContainer } from "shared/components/shared/core/Container";
import BusinessHeader, {
  BusinessOrderHeader,
} from "public/components/business/shared/BusinessHeader";
import { ErrorMessagePage } from "shared/components/shared/core/Error";

export default () => {
  const business = useBaseSelector("order.cart.business");
  const businessTable = useBaseSelector("order.cart.businessTable");
  const selections = useBaseSelector("order.cart.selections");

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      mergeOrderNodeData({
        node: "cart",
        data: {
          businessTable,
          business,
        },
      })
    );
  }, []);

  const onSubmit = () => {
    const payload = {
      businessId: business?.id,
      businessTableId: businessTable?.id,
      selections: selections.map((it) => ({
        units: it.units,
        businessFoodId: it.id,
        excludeIngredientsIds: _.map(it?.food?.excludeIngredients, "id"),
        addSupplementsIds: _.map(it?.food?.addSupplements, "id"),
      })),
    };

    dispatch(
      createOrderAsync({
        payload: {
          body: payload,
          query: {
            variant: "public",
          },
        },
        options: {
          onSuccess: (data) => {
            navigate(`/order/${data?.id}`);
            dispatch(setOrderInit("cart"));
          },
        },
      })
    );
  };

  if (selections?.length === 0) {
    return <ErrorMessagePage message="Panier vide" />;
  }

  return (
    <>
      <LayoutContentContainer horizontalPadding={true}>
        <BusinessCart
          onSubmit={onSubmit}
          variant="cart"
          data={{
            business,
            businessTable,
            selections,
          }}
        />
      </LayoutContentContainer>
    </>
  );
};
