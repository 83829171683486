import _ from "lodash";
import {
  buildTabDefaultIcon,
  buildTabSelectedIcon,
} from "public/constants/tabs.constant";

export const tabsMapBusiness = {
  menu: {
    label: "Menu",
    value: "", //on route split by '/' index 2, value is undefined, tab will ne shown as selected
    route: "/",
    defaultIcon: buildTabDefaultIcon("menu"),
    selectedIcon: buildTabSelectedIcon("menu"),
  },
  tables: {
    label: "Tables",
    value: "tables",
    route: "tables",
    defaultIcon: buildTabDefaultIcon("tables"),
    selectedIcon: buildTabSelectedIcon("tables"),
  },
  cart: {
    label: `Panier`,
    value: "cart",
    route: "/cart",
    defaultIcon: buildTabDefaultIcon("cart"),
    selectedIcon: buildTabSelectedIcon("cart"),
  },
  order: {
    label: "Commandes",
    value: "order",
    route: "/order",
    defaultIcon: buildTabDefaultIcon("order"),
    selectedIcon: buildTabSelectedIcon("order"),
  },
  orderChef: {
    label: "Chef",
    value: "orderChef",
    route: "/orderChef",
    defaultIcon: buildTabDefaultIcon("orderChef"),
    selectedIcon: buildTabSelectedIcon("orderChef"),
  },
  business: {
    label: "Business",
    value: "business",
    route: "/business",
    defaultIcon: buildTabDefaultIcon("business"),
    selectedIcon: buildTabSelectedIcon("business"),
  },
  notification: {
    label: "Notifications",
    value: "notification",
    route: "notification",
    defaultIcon: buildTabDefaultIcon("notification"),
    selectedIcon: buildTabSelectedIcon("notification"),
  },
  me: {
    label: "Moi",
    value: "me",
    route: "/me",
    defaultIcon: buildTabDefaultIcon("me"),
    selectedIcon: buildTabSelectedIcon("me"),
  },
};

const tabsRoleMap = {
  owner: ["menu", "tables", "cart", "order", "business", "me"],
  admin: ["menu", "tables", "cart", "order", "business", "me"],
  cashier: ["menu", "tables", "cart", "order", "business", "me"],
  waiter: ["menu", "tables", "cart", "order", "business", "me"],
  chef: ["menu", "cart", "order", "business", "me"],
};

const sidebarTabsRoleMap = {
  owner: ["menu", "tables", "order", "orderChef", "business", "notification"],
  admin: ["menu", "tables", "order", "orderChef", "business", "notification"],
  cashier: ["menu", "tables", "order", "orderChef", "business", "notification"],
  waiter: ["menu", "tables", "order", "orderChef", "business", "notification"],
  chef: ["menu", "tables", "order", "orderChef", "business", "notification"],
};

export const tabsBreadcrumbs = {
  menu: {
    title: "Menu",
    icon: "hugeicons:menu-restaurant",
    link: "/",
  },
  tables: {
    title: "Tables",
    icon: "hugeicons:table-round",
    link: "/tables",
  },
  me: {
    title: "Moi",
    icon: "hugeicons:user",
    link: "/me",
  },
  about: {
    title: "A propos",
    icon: "hugeicons:information-circle",
    link: "/about",
  },
  notification: {
    title: "Notification",
    icon: "hugeicons:notification-02",
    link: "/notification",
  },
  order: {
    title: "Commandes",
    icon: "hugeicons:border-full",
    link: "/order",
  },
  orderHistory: {
    title: "Historique",
    icon: "hugeicons:time-02",
    link: "/order/history",
  },
  orderChef: {
    title: "Commandes Vue Chef",
    icon: "hugeicons:chef-hat",
    link: "/orderChef",
  },
  orderDetails: (params) => ({
    title: params?.id,
    icon: "hugeicons:information-circle",
    link: "/order/" + params?.id,
  }),
  orderEdit: (params) => ({
    title: params?.id,
    icon: "hugeicons:pencil-edit-02",
    link: "/order/" + params?.id,
  }),
  business: {
    title: "Business",
    icon: "hugeicons:cayan-tower",
    link: "/business",
  },
  businessManageProfile: {
    title: "Infos",
    icon: "hugeicons:information-circle",
    link: "/business/manage/profile",
  },
  businessManageMenu: {
    title: "Configurer le menu",
    icon: "hugeicons:menu-restaurant",
    link: "/business/manage/menu/items",
  },
  businessManageTemplate: {
    title: "Configure la template du menu",
    icon: "hugeicons:layout-01",
    link: "/business/manage/menu/template",
  },
  businessManageTeam: {
    title: "Manager l'équipe",
    icon: "hugeicons:user-settings-01",
    link: "/business/manage/team",
  },
  businessManageTeamAdd: {
    title: "Ajouter un personnel",
    icon: "hugeicons:user-add-01",
    link: "/business/manage/team/add",
  },
  businessTeam: {
    title: "Manager l'équipe",
    icon: "hugeicons:user-group",
    link: "/business/manage/team",
  },
};

export const getTabsBusinessByRole = (role, variant) =>
  _.chain(tabsMapBusiness)
    .pick(variant === "mobile" ? tabsRoleMap[role] : sidebarTabsRoleMap[role])
    .values()
    .value();
