import _ from "lodash";
import { lazy, useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routesBusinessConstant from "business/constants/routesBusiness.constant";
import routesPublicConstant from "public/constants/routesPublic.constant";
import { ApplicationContext } from "context";

const RootRouterPublic = lazy(() => import("RootRouterPublic"));
const RootRouterBusiness = lazy(() => import("RootRouterBusiness"));

const publicRouter = createBrowserRouter(
  [
    {
      path: "/",
      element: <RootRouterPublic />,
      children: routesPublicConstant,
    },
  ],
  {
    basename: "/",
  }
);

const internalRouter = createBrowserRouter(
  [
    {
      path: "/",
      element: <RootRouterBusiness />,
      children: routesBusinessConstant,
    },
  ],
  {
    basename: "/business",
  }
);

export default () => {
  const [variantContext, setApplicationContext] = useState();

  const [rootRouter, setRootRouter] = useState();

  useEffect(() => {
    const pathname = window.location.pathname;
    const isBusiness = _.chain(pathname)
      .split("/")
      .nth(1)
      .isEqual("business")
      .value();

    if (isBusiness) {
      setApplicationContext("business");
      setRootRouter(internalRouter);
    } else {
      setApplicationContext("public");
      setRootRouter(publicRouter);
    }
  }, []);

  if (!rootRouter) {
    return <></>;
  }

  return (
    <ApplicationContext.Provider value={variantContext}>
      <RouterProvider router={rootRouter} />
    </ApplicationContext.Provider>
  );
};
