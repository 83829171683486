import BaseButton from "shared/components/shared/base/BaseButton";
import BaseDivider from "shared/components/shared/base/BaseDivider";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";

export default () => {
  const { anonymousUUID } = useSelector((state) => state.user.me);

  const navigate = useNavigate();

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <div className="w-full h-auto flex flex-col items-center justify-center mb-4">
        {anonymousUUID && (
          <QRCode value={anonymousUUID} className="w-1/2 h-auto mb-8" />
        )}
        <div className="w-full text-center">ID: {anonymousUUID}</div>
      </div>
      <BaseDivider className="mb-4" />
      <BaseButton.Contained primary
        className="w-full flex flex-row items-center justify-center rounded-full py-2 ml-1"
        onClick={() => navigate("/me/auth")}
      >
        S'authentifier
      </BaseButton.Contained>
    </div>
  );
};
