import { useSelector } from "react-redux";
import UserProfile from "./UserProfile";
import AnonymousOrAuth from "./AnonymousOrAuth";

export default () => {
  const { data: user } = useSelector((state) => state.user?.me);

  if (user) {
    return <UserProfile />;
  }

  return (
    <div className="w-full h-screen px-4 lg:px-0">
      <AnonymousOrAuth />
    </div>
  );
};
