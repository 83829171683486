import { useState, useEffect } from "react";
import Login from "shared/components/user/Login";
import Register from "shared/components/user/Register";
import classnames from "classnames";

export const Auth = ({ className, ...props }) => {
  const [view, setView] = useState("register");

  return (
    <div
      className={classnames(
        "w-full h-full flex flex-col items-center justify-end",
        className
      )}
    >
      {view === "login" && (
        <Login onChangeToRegisterClick={() => setView("register")} {...props} />
      )}
      {view === "register" && (
        <Register onChangeToLoginClick={() => setView("login")} {...props} />
      )}
    </div>
  );
};

export const MobileAuth = (props) => (
  <div className="w-full h-full flex flex-col items-center justify-start px-4 py-[50px]">
    <Auth {...props} />
  </div>
);
