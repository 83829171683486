import _ from "lodash";
import { Navigate } from "react-router-dom";
import { PageNotFound } from "shared/components/shared/core/Error";
import NotificationPage from "shared/components/notification/NotificationPage";
import UserProfileEdition from "shared/components/user/UserProfileEdition";
import UserProfileTab from "shared/components/user/UserProfileTab";
import BusinessMenuTabBusiness from "business/components/business/BusinessMenuTabBusiness";
import BusinessCartTabBusiness from "business/components/order/cart/BusinessCartTabBusiness";
import OrdersTabBusiness from "business/components/order/OrdersTabBusiness";
import BusinessProfileTabBusiness from "business/components/business/BusinessProfileTabBusiness";
import BusinessManageTeam from "business/components/business/BusinessManageTeam";
import BusinessManageTeamAdd from "business/components/business/BusinessManageTeamAdd";
import OrderEditPage from "shared/components/order/shared/OrderEditPage";
import OrderPageBusiness from "business/components/order/OrderPageBusiness";
import AboutPage from "shared/components/shared/core/AboutPage";
import BusinessManageMenuItems from "business/components/business/shared/BusinessManageMenuItems";
import BusinessManageMenuTemplate from "business/components/business/shared/BusinessManageMenuTemplate";
import BusinessManageProfile from "business/components/business/BusinessManageProfile";
import BusinessTablesTabBusiness from "business/components/business/BusinessTablesTabBusiness";
import OrdersChefTabBusiness from "business/components/order/OrdersChefTabBusiness";
import OrdersHistoryPageBusiness from "business/components/order/OrdersHistoryPageBusiness";
import BusinessTeam from "business/components/business/BusinessTeam";

export default [
  {
    path: "",
    element: <BusinessMenuTabBusiness />,
    exact: true,
  },
  {
    path: "/tables",
    element: <BusinessTablesTabBusiness />,
    exact: true,
  },
  {
    path: "/cart",
    element: <BusinessCartTabBusiness />,
    exact: true,
  },
  {
    path: "/notification",
    element: <Navigate to="/notification/main" replace />,
    exact: true,
  },
  {
    path: "/notification/main",
    element: <NotificationPage />,
    exact: true,
  },
  {
    path: "/notification/system",
    element: <NotificationPage />,
    exact: true,
  },
  {
    path: "/order",
    element: <OrdersTabBusiness />,
    exact: true,
  },
  {
    path: "/order/history",
    element: <OrdersHistoryPageBusiness />,
    exact: true,
  },
  {
    path: "/orderChef",
    element: <OrdersChefTabBusiness />,
    exact: true,
  },
  {
    path: "/order/:id/edit",
    element: <OrderEditPage />,
    exact: true,
  },
  {
    path: "/order/:id",
    element: <OrderPageBusiness />,
    exact: true,
  },
  {
    path: "/business",
    element: <BusinessProfileTabBusiness />,
    exact: true,
  },
  {
    path: "/business/manage/team",
    element: <BusinessManageTeam />,
    exact: true,
  },
  {
    path: "/business/manage/team/add",
    element: <BusinessManageTeamAdd />,
    exact: true,
  },
  {
    path: "/business/team",
    element: <BusinessTeam />,
    exact: true,
  },
  {
    path: "/business/manage/profile",
    element: <BusinessManageProfile />,
    exact: true,
  },
  {
    path: "/business/manage/menu/items",
    element: <BusinessManageMenuItems />,
    exact: true,
  },
  {
    path: "/business/manage/menu/template",
    element: <BusinessManageMenuTemplate />,
    exact: true,
  },
  {
    path: "/me",
    element: <UserProfileTab />,
    exact: true,
  },
  {
    path: "/me/edit",
    element: <UserProfileEdition />,
    exact: true,
  },
  {
    path: "/about",
    element: <AboutPage />,
    exact: true,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
];
