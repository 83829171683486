import moment from "moment";

export const getDuration = (dateTime) => {
  return moment(dateTime).fromNow();
};

export const getApplicationPeriod = () => {
  var currentHour = moment().format("HH");

  if (currentHour >= 3 && currentHour < 12) {
    return "morning";
  } else if (currentHour >= 12 && currentHour < 18) {
    return "afternoon";
  } else if (currentHour >= 18 && currentHour < 22) {
    return "evening";
  } else if (currentHour >= 22 || currentHour < 3) {
    return "night";
  }
  return "default";
};

export const sendMessageToNative = (message) => {
  window.ReactNativeWebView?.postMessage(JSON.stringify(message));
};

export const isUsingMobile = window?.ReactNativeWebView !== undefined;
