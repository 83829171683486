import { useEffect, useState } from "react";
import Login from "./Login";
import Register from "./Register";
import HeaderBack from "shared/components/shared/core/HeaderBack";
import {
  LayoutContainer,
  LayoutContentContainer,
} from "shared/components/shared/core/Container";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MobileAuth } from "shared/components/user/Auth";

export default () => {
  const { data: user } = useSelector((state) => state.user.me);

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/me");
    }
  }, [user]);

  return (
    <div className="w-full h-full overflow-y-scroll pb-[50px]">
      <MobileAuth variant="public" />
    </div>
  );
};
