import classnames from "classnames";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";
import useBaseSelector from "shared/hooks/useBaseSelector";
import colors from "tailwindcss/colors";
import { useDispatch } from "react-redux";
import { setSharedNodeData } from "shared/redux/shared.slice";
import { Icon } from "@iconify/react";
import useScrollWatch from "shared/hooks/useScrollWatch";
import { ApplicationPeriodContext } from "context";

export const HeaderMobile = ({ visible = true }) => {
  const applicationPeriod = useContext(ApplicationPeriodContext);

  const {
    data: { data: notifications },
  } = useBaseSelector("notification.main");

  const [notViewedCount, setNotViewedCount] = useState(0);

  const { scrollDirection } = useScrollWatch();

  const dispatch = useDispatch();

  useEffect(() => {
    setNotViewedCount(
      _.chain(notifications)
        .filter((it) => !it.isViewed)
        .size()
        .value()
    );
  }, [notifications]);

  const onDrawerIconClick = () => {
    dispatch(
      setSharedNodeData({
        node: "drawer",
        data: {
          isOpen: true,
        },
      })
    );
  };

  const navigate = useNavigate();
  return (
    <div
      className={classnames(
        "w-full h-auto flex flex-row items-center justify-between _glass sticky top-0 py-3 pl-4 pr-6 z-40",
        {
          hidden: !visible,
          "-translate-y-[100px] duration-300": scrollDirection === "down",
          "duration-300": scrollDirection === "up",
          _headerShadow:
            applicationPeriod === "evening" || applicationPeriod === "night",
        }
      )}
    >
      <HeaderSequence />

      <div className="flex flex-row items-start justify-start">
        <div className="relative">
          <Icon
            icon="hugeicons:notification-02"
            color={colors.gray[200]}
            fontSize={24}
            onClick={() => navigate("/notification")}
          />
          {notViewedCount > 0 && (
            <div className="w-[25px] h-[25px] rounded-full flex flex-col items-center justify-center absolute -top-3 -left-4 bg-primary-main">
              <div className="text-white text-sm font-semibold">
                {notViewedCount}
              </div>
            </div>
          )}
        </div>
        <Icon
          icon="hugeicons:menu-02"
          color={colors.gray[200]}
          fontSize={24}
          className="ml-4"
          onClick={onDrawerIconClick}
        />
      </div>
    </div>
  );
};

export const HeaderTablet = () => {
  const {
    data: { data: notifications },
  } = useBaseSelector("notification.main");

  const [notViewedCount, setNotViewedCount] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    setNotViewedCount(
      _.chain(notifications)
        .filter((it) => !it.isViewed)
        .size()
        .value()
    );
  }, [notifications]);

  const onDrawerIconClick = () => {
    dispatch(
      setSharedNodeData({
        node: "drawer",
        data: {
          isOpen: true,
        },
      })
    );
  };

  const navigate = useNavigate();
  return (
    <div
      className={classnames(
        "w-full h-[60px] flex flex-row items-center justify-between border-b-[1px] border-custom-border-main sticky top-0 pl-4 pr-6 z-40"
      )}
    >
      <HeaderSequence />

      <div className="grow"></div>

      <div className="w-auto flex flex-row items-start justify-start">
        <div className="relative">
          <Icon
            icon="hugeicons:notification-02"
            color={colors.gray[200]}
            fontSize={24}
            onClick={() => navigate("/notification")}
          />
          {notViewedCount > 0 && (
            <div className="w-[25px] h-[25px] rounded-full flex flex-col items-center justify-center absolute -top-3 -left-4 bg-primary-main">
              <div className="text-white text-sm font-semibold">
                {notViewedCount}
              </div>
            </div>
          )}
        </div>
        <Icon
          icon="hugeicons:menu-02"
          color={colors.gray[200]}
          fontSize={24}
          className="ml-4"
          onClick={onDrawerIconClick}
        />
      </div>
    </div>
  );
};

const HeaderSequence = () => {
  const [fixed, setFixed] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setFixed(true);
    }, 20000);
  }, []);

  if (fixed) {
    return (
      <div className="text-2xl font-light _fontTangerine tracking-widest">
        Swiftr
      </div>
    );
  }

  return (
    <TypeAnimation
      sequence={[
        "Swiftr",
        1000,
        "Best Menus",
        1000,
        "Best Restaurants",
        1000,
        "Swiftr",
      ]}
      wrapper="span"
      speed={50}
      repeat={2}
      className="text-2xl  font-light _fontTangerine tracking-widest"
    />
  );
};
